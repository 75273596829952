import React from 'react'
import Layout from '../../components/Layout'
import {Location} from '@reach/router'
import Content from '../../components/Content'
import contentImg from '../../images/2up_VidSecurity_Home_iPhone_print-1.jpg'
import contentImg2 from '../../images/simonxti5-product_image_1400x1050 (1).jpg'
import contentImg3 from '../../images/ADC_webMobile_family (1).jpg'
export default () => (  
    <Location>
        {
            props=>(
                <Layout location={props.location}>
                   <Content>
                   <h2>
                       Professionally Monitored Security
                   </h2>
                    <p>
                        Keep your property safe with 24/7 professional monitoring and emergency response. US Alarms systems are monitored by licensed central monitoring stations across the country, so you can count on help whenever you need it.
                    </p>

                    <h2>
                        Dedicated Connection
                    </h2>
                    <p>
                        US Alarms uses a tamper-resistant cellular connection, dedicated solely to your security system. This signaling technology is reliable, and works even if your phone line, cable, broadband and power are down.
                    </p>

                    <h2>
                        Total Control From Anywhere
                    </h2>
                    <p>
                        Arm and disarm your system from anywhere, check your system’s status and view recent activity at home using US Alarms’ powerful smart home security app.
                    </p>
                    <div className="row center" style={{marginTop:'2rem'}}>
                    <img src={contentImg}/>
                    </div>
                    
                    <h2>Unique User Codes</h2>
                    <p>Easily create and disable user codes. Give each user a unique code, then see who has armed or disarmed the system, and when. You can disable codes within minutes from your computer or tablet.</p>
                    <h2>
                        Notifications
                    </h2>
                    <p>
                        Stay aware of important activity at your property anytime, not just when there’s an emergency, with customizable text, push notifications and email alerts.
                    </p>

                    <ul><li>Get arming reminders.</li>
                    <li>See who has armed or disarmed the system — even get a photo of the activity.</li>
                    <li>Get an alert in an alarm event. The monitoring station is also immediately notified.</li>
                    <li>Know if motion is detected or if a specific door or window is opened or left ajar when it shouldn’t be.</li></ul>
                    <h2>
                        Security Panels
                    </h2>
                    <p>
                        Choose from a variety of panels and take advantage of helpful features.
                    </p>
                    <div className="row center" style={{marginTop:'2rem'}}>
                    <img src={contentImg2} style={{maxWidth:'250px'}}/>
                    </div>
                    <h3>
                        Crash & Smash™ Protection
                    </h3>
                    <p>
                        Patented technology ensures your system will work even if the panel is smashed by intruders.
                    </p>
                    <h3>
                        2-Way Voice
                    </h3>
                    <p>
                        Talk to the emergency dispatcher directly through the panel.
                    </p>

                    <h3>
                        Extreme Weather Alerts
                    </h3>

                    <p>
                        Using real-time local weather data, the panel will alert you to dangerous weather conditions
                    </p>
                    <h2>
                        Video Monitoring
                    </h2>
                    <p>
                        See What You Care About Instantly
                    </p>

                    <p>
                        Keep an eye on activity at your property with professionally hosted video monitoring.
                    </p>
                    <div className="row center" style={{marginTop:'2rem'}}>
                        <img src={contentImg3} style={{maxWidth:'350px'}}/>
                    </div>

                    <h2>
                        Video Monitoring from Anywhere
                    </h2>
                    <p>Pan and Tilt: Remotely move the Pan/Tilt camera via the web, smartphone or tablet to see the different views of a location. Simultaneously view multiple locations on the same screen.</p>

                    <p>Live Video: HD quality and great lowlight performance make for crystal clear video. Use our smartphone or tablet to view live streaming video while away from your property.</p>

                    <p>Safe Storage: Tamper-resistant offsite storage, wireless communications, and cloud hosting provide better reliability. Integrate existing video cameras with your US Alarms security system.</p>

                    <h2>
                        Doorbell Camera
                    </h2>

                    <p>
                        See who’s at the front door with the US Alarms WiFi Doorbell Camera. Two-way voice through the US Alarms app lets you speak to visitors at the door, from anywhere.
                    </p>

                    <h2>
                        Continuous Recording
                    </h2>

                    <p>
                        Capture 24/7, high-definition, continuous recording with the Stream Video Recorder (SVR). The SVR is bandwidth optimized so it won’t slow your internet connection.
                    </p>

                    <h2>
                        Video Alerts
                    </h2>

                    <p>
                        Smarter than stand-alone video, US Alarms video is connected with your security system, so you can get a video alert when any system activity is detected. See what’s happening if the system is disarmed unexpectedly, or if the alarm goes off.
                    </p>

                    <p>
                        You can receive alerts by text or email, and customize them by time, day, camera and motion.
                    </p>
                   
                   </Content>
                </Layout>
            )
        }
    </Location>
)